import ContactForm from "../components/ContactForm";
import cn from "classnames";

const Contact = ({ active }) => {
  return (
    <div className={cn("grid", { active: active === true })}>
      <div className="grid-item">
        <p>
          If you have any questions, comments, or concerns one of our staff
          members will be happy to help. Fill out this form and one of us will
          be sure to get back to you as soon as possible.
        </p>
      </div>
      <div className="grid-item">
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;

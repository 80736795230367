import { ReactComponent as HomeI } from "../images/home.svg";
import { ReactComponent as PhoneI } from "../images/call.svg";
import { ReactComponent as ProjectsI } from "../images/images.svg";
import { ReactComponent as QuoteI } from "../images/cart.svg";
import Home from "../pages/Home";
import Projects from "../pages/Projects";
import Quote from "../pages/Quote";
import Contact from "../pages/Contact";

const tabs = [
  {
    title: "Home",
    id: "home",
    icon: <HomeI />,
    content: Home,
  },
  {
    title: "Projects",
    id: "projects",
    icon: <ProjectsI />,
    content: Projects,
  },
  {
    title: "Quote",
    id: "quote",
    icon: <QuoteI />,
    content: Quote,
  },
  {
    title: "Contact",
    id: "contact",
    icon: <PhoneI />,
    content: Contact,
  },
];

export default tabs;

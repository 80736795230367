import TabComponent from "./Tabs";
import Header from "./Header";
import tabs from "../data/TabInfo";

function App() {
  return (
    <div className="main-container">
      <Header />
      <TabComponent tabs={tabs} />
    </div>
  );
}

export default App;

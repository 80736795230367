import React from "react";
import QuoteForm from "../components/QuoteForm";
import cn from "classnames";

const Quote = ({ active }) => {
  return (
    <div className={cn("grid", { active: active === true })}>
      <div className="grid-item">
        <p>
          Need a quote for a specific service? Fill out the following form and
          someone will reach out to you within 5 - 7 business days. <br />
          <br /> Unlike most websites we do not store or sell any of the
          information you provide.
        </p>
      </div>
      <div className="grid-item">
        <QuoteForm />
      </div>
    </div>
  );
};

export default Quote;

import React from "react";
import logo from "../images/logo_no_back.svg";
import cn from "classnames";

const Projects = ({ active }) => {
  return (
    <div className={cn("projects-grid-int", { active: active === true })}>
      <div className="grid-item">
        <h1>We are still working on this page please check back later!</h1>
      </div>
      <div className="grid-item">
        <img src={logo} className="gif" alt="logo" />
      </div>
    </div>
  );
};

export default Projects;

import React from "react";
import logo from "../images/logo_no_back.svg";
import { useGlobalContext } from "./context";

const Loading = () => {
  const { loading, toggleOverlay, sending } = useGlobalContext();

  return (
    <div
      className="loading-overlay"
      style={{ display: loading ? "flex" : "none" }}
    >
      <div className="loading-content">
        <div className="loading-logo-container">
          <img className="loading-logo" src={logo} alt="logo" />
        </div>
        <div className="loading-text">
          <h3>
            {sending
              ? "Sending..."
              : "Someone from our team will reach out as soon as possible!"}
          </h3>
        </div>
        {sending === false && (
          <input
            type="submit"
            value="OK"
            className="loading-btn"
            onClick={toggleOverlay}
          />
        )}
      </div>
    </div>
  );
};

export default Loading;

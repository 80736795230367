import React from "react";
import logo from "../images/logo_no_back.svg";
import cn from "classnames";

const Home = ({ active }) => {
  return (
    <>
      <div className={cn("grid", { active: active === true })}>
        <div className="grid-item">
          <p>
            Welcome to Digital And Audio Solutions where we have a solution for
            your problems.
            <br />
            <br />
            We are a small team of engineers, photograhpers, and Djs that focus
            on: developing websites/applications, taking and modifying
            pro-quality pictures, and last but not least bringing the fun to
            your party/event. Check out some of our work on the projects tab and
            feel free to reach out with any questions!
          </p>
        </div>
        <div className="grid-item">
          <img src={logo} className="logo" alt="logo" />
        </div>
      </div>
    </>
  );
};

export default Home;

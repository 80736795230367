import { useState, useRef } from "react";
import emailjs from "emailjs-com";
import { useGlobalContext } from "./context";

const QuoteForm = () => {
  const formRef = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const { toggleOverlay, toggleSending } = useGlobalContext();

  const requestQuote = (e) => {
    e.preventDefault();

    //turn on overlay
    toggleOverlay();
    //turn off ok button
    toggleSending();
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_QUOTE_ID,
        {
          from_name: e.target[0].value,
          from_email: e.target[1].value,
          product: e.target[2].value,
          from_phone: e.target[3].value,
          message: e.target[4].value,
        },
        "user_iwZFRQcFk5RVJK3kKDgV1"
      )
      .then(() => toggleSending());
  };
  return (
    <form ref={formRef} className="form" onSubmit={requestQuote}>
      <label>
        Name:
        <input
          type="text"
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
        />
      </label>
      <label>
        Email:
        <input
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </label>
      <label>
        Product:
        <select className="quote-drop">
          <option>Mobile Application</option>
          <option>Website</option>
          <option>DJ Services</option>
          <option>Other</option>
        </select>
      </label>
      <label>
        Phone Number:
        <input
          type="tel"
          required
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </label>
      <label>
        Description:
        <textarea className="request-text" required />
      </label>
      <span className="form-btn-container">
        <input className="form-btn" type="submit" value="Request Quote" />
      </span>
    </form>
  );
};

export default QuoteForm;

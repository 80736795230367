import React, { useContext, useReducer } from "react";
import reducer from "./Reducer";
const Context = React.createContext();

const initState = {
  loading: false,
  sending: false,
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  const toggleOverlay = () => {
    dispatch({ type: "TOGGLE_OVERLAY" });
  };

  const toggleSending = () => {
    dispatch({ type: "TOGGLE_SENDING" });
  };

  return (
    <Context.Provider
      value={{
        ...state,
        toggleOverlay,
        toggleSending,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(Context);
};

export { Context, AppProvider };

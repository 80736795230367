const reducer = (state, action) => {
  if (action.type === "TOGGLE_OVERLAY") {
    return { ...state, loading: !state.loading };
  }
  if (action.type === "TOGGLE_SENDING") {
    return { ...state, sending: !state.sending };
  }
  throw new Error("No matching action type");
};

export default reducer;

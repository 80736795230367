import { useState, useRef } from "react";
import emailjs from "emailjs-com";
import { useGlobalContext } from "./context";

const ContactForm = () => {
  const formRef = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const { toggleOverlay, toggleSending } = useGlobalContext();

  const contactUs = (e) => {
    e.preventDefault();

    //turn on overlay
    toggleOverlay();
    //turn off ok button
    toggleSending();

    emailjs
      .send(
        "service_4yrvd7k",
        "template_91nuvdf",
        {
          from_name: e.target[0].value,
          message: e.target[3].value,
          from_email: e.target[1].value,
          from_phone: e.target[2].value,
        },
        "user_iwZFRQcFk5RVJK3kKDgV1"
      )
      .then(() => toggleSending());
  };
  return (
    <form ref={formRef} className="form" onSubmit={contactUs}>
      <label>
        Name
        <input
          type="text"
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
        />
      </label>
      <label>
        Email
        <input
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </label>
      <label>
        Phone Number
        <input
          type="tel"
          required
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </label>
      <label>
        Message
        <textarea className="request-text" required />
      </label>
      <span className="form-btn-container">
        <button className="form-btn">Send</button>
      </span>
    </form>
  );
};

export default ContactForm;
